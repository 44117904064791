<template>
  <div>
    <div class="d-block d-md-flex align-items-center justify-content-between mb-2">
      <h1 class="fw-bold mb-0 text-uppercase">Class detail</h1>
      <div class="d-flex align-items-center ">
      <div class="class_status_changer ms-auto me-2">
        <template v-if="class_details && class_details.status !=='released'">
          <label for="status" class="visually-hidden">Status</label>
          <select class="form-select mw-6" v-model="status_key" @change="change_status" id="status">
            <option v-for="status in statuses" :key="'status_'+status.value" :value="status.value">{{ status.label }}
            </option>
          </select>
        </template>
        <template v-if="class_details && class_details.status ==='released'">
          <span data-bs-toggle="tooltip" data-bs-placement="top" :title="'Released'">
            <span class="status-badge me-1 bg-success"></span>Released
          </span>
        </template>
      </div>
      <button class="btn btn-secondary rounded-pill py-2 text-uppercase text-nowrap" @click="$router.back()">
        <i class="fa fa-arrow-left"></i>
        Back
      </button>
      </div>
    </div>
    <div class="card">
      <template v-if="loading">
        <div class="py-5 d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card-body">
          <div class="d-flex flex-wrap flex-md-nowrap">
            <h2 class="mb-0 me-3 fw-bolder order-1">{{ class_details.name }}</h2>
            <div class="col-2 ms-auto d-flex align-items-center justify-content-end order-2 order-md-3">
              <span class="me-3 segment_icons fs-6 flex-shrink-0"
                    @click="$router.push({name:'EditClass', params: {id: class_details.id}})"><i
                  class="fal fa-edit" style="padding-left: 3px;padding-bottom: 1px"></i></span>
              <span class="segment_icons fs-6 flex-shrink-0" @click="deleteClass(class_details.id)"><i
                  class="fal fa-trash "></i></span>
            </div>
            <div class="instructors d-flex flex-wrap order-3 order-md-2 col-12 col-md-auto flex-md-shrink-1">
              <div v-for="instructor in class_details.instructors" :key="'instructor-'+instructor.id"
                   class="d-flex align-items-center me-3 mb-1">
                <div class="flex-shrink-0">
                  <img :src="instructor.image" :alt="instructor.name" style="height: 30px;width: 30px"
                       class="rounded-circle">
                </div>
                <div class="flex-grow-1 ms-1">
                  <span class="fw-bold text-uppercase">{{ instructor.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row" style="--bs-columns: 7; --bs-gap: 1rem;">
            <div class="col">
              <label class="text-secondary d-block text-uppercase text-nowrap">Type</label>
              <span class="h3 fw-bold text-uppercase">
                {{ class_details.class_type ? class_details.class_type.name : '' }}
              </span>
            </div>
            <div class="col">
              <label class="text-secondary d-block text-uppercase text-nowrap">Language</label>
              <span class="h3 fw-bold text-uppercase">{{ class_details.language.name }}</span>
            </div>
            <div class="col">
              <label class="text-secondary d-block text-uppercase text-nowrap">Length</label>
              <span class="h3 fw-bold text-uppercase">{{ class_details.length.name }}</span>
            </div>
            <div class="col">
              <label class="text-secondary d-block text-uppercase text-nowrap">Production Date</label>
              <span class="h3 fw-bold text-uppercase">
                {{ $formatDate(class_details.production_date) }}
              </span>
            </div>
            <div class="col">
              <label class="text-secondary d-block text-uppercase text-nowrap">Production Time</label>
              <span class="h3 fw-bold text-uppercase">
                {{ $formatTime(class_details.production_date) }}
              </span>
            </div>
            <div class="col">
              <label class="text-secondary d-block text-uppercase text-nowrap">Release Date</label>
              <span class="h3 fw-bold text-uppercase">
                {{ $formatDateTime(class_details.release_date) }}
              </span>
            </div>
          </div>
          <ul class="list-unstyled d-flex my-3 py-0">
            <li class="session_tag text-secondary me-3" v-for="tag in class_details.tags" :key="'tag-'+tag">
              {{ tag }}
            </li>
          </ul>
          <hr>
          <div v-html="class_details.description"></div>
          <hr>
          <div v-show="!audio_loading">
            <div class="row justify-content-between mb-2" id="wf_container">
              <div class="col-3 col-lg-1">
                <input class="form-control form-control-sm flex-grow-0 text-center  bg-white" :value="currentTime" readonly>
              </div>
              <div class="col-3 col-lg-1">
                <input class="form-control form-control-sm flex-grow-0 text-center bg-white" :value="maxTime" readonly>
              </div>
            </div>
            <div id="waveform"></div>
          </div>
          <hr>
          <template v-if="all_loaded">
            <div class="segment_annotation_wrapper">
              <div class="item_list" v-for="item in all_items" :key="item.type+'-'+item.id">
                <template v-if="item.type === 'segment'">
                  <div class="item_heading d-flex align-items-center" @click="toggleOpenSegment(item.id)">
                    <h2 class="mb-0 font-weight-medium">{{ item.name }}</h2>
                    <div class="segment_color ms-2" :style="{'background-color':item.color}"></div>
                    <div class="ms-auto">
                      <i class="fa fa-chevron-down" :class="{
                        'fa-chevron-down': !openEditSegments.includes(item.id),
                        'fa-chevron-up': openEditSegments.includes(item.id),
                      }"></i>
                    </div>
                  </div>
                  <div class="item_form" v-if="openEditSegments.includes(item.id)">
                    <audio-segment-view :segment="item"></audio-segment-view>
                  </div>
                </template>
                <template v-else>
                  <div class="item_heading d-flex align-items-center" @click="toggleOpenAnnotation(item.id)">
                    <h2 class="mb-0 font-weight-medium">{{ item.name }}</h2>
                    <span class="fw-bold h5 ms-3 mb-0">{{ item.instant | toHMTime }}</span>
                    <div class="ms-auto">
                      <i class="fa fa-chevron-down" :class="{
                        'fa-chevron-down': !openEditAnnotations.includes(item.id),
                        'fa-chevron-up': openEditAnnotations.includes(item.id),
                      }"></i>
                    </div>
                  </div>
                  <div class="item_form" v-if="openEditAnnotations.includes(item.id)">
                    <audio-annotation-view :annotation="item"></audio-annotation-view>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <div class="py-3 d-flex justify-content-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <delete-modal ref="delete_modal" @confirm="destroyClass"></delete-modal>
  </div>
</template>

<script>

import AudioSegmentView from "@/views/Class/AudioSegmentView";
import AudioAnnotationView from "@/views/Class/AudioAnnotationView";
import DeleteModal from "@/components/DeleteModal";
import WaveSurfer from "wavesurfer.js";
import WaveSurferRegions from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import WaveSurferMarkers from "wavesurfer.js/dist/plugin/wavesurfer.markers";

export default {
  name: "ClassDetails",
  components: {DeleteModal, AudioAnnotationView, AudioSegmentView},
  props: ['id'],
  data() {
    return {
      roles: [],
      class_details: null,
      segments: [],
      annotations: [],
      loading: true,
      segments_loaded: false,
      annotations_loaded: false,
      openEditAnnotations: [],
      openEditSegments: [],
      status_key: null,
      audio_loading: true,
      audio_details: [],
      audio_url: null,
      wavesurfer: null,
      max_length: 0,
      playTime: 0,
      statuses: [
        {
          value: 0,
          label: 'Draft'
        },
        {
          value: 1,
          label: 'Ready'
        },
        {
          value: 2,
          label: 'Filmed'
        },
      ]
    }
  },
  mounted() {
    this.loading = true;
    this.fetchClass(this.id);
  },
  methods: {
    change_status() {
      this.$http.put(`/sessions/${this.id}/status`, {status: this.status_key}).then(({data}) => {
        this.$noty.success(data.message)
        this.class_details.status_key = this.status_key;
      })
          .catch(({response}) => {
            this.status_key = Object.assign({}, this.class_details).status_key;
            if (response && response.data && response.data.message) {
              this.$noty.error(response.data.message)
            }
          })
    },
    fetchClass() {
      this.$http.get(`/sessions/${this.id}`).then(async ({data}) => {
        this.class_details = data.data;
        this.status_key = Object.assign({}, this.class_details).status_key;
        this.loading = false;
        this.audio_loading = true;
        let {audio} = await this.checkAudio();
        if(audio) {
          // this.audio_data = details;
          this.audio_url = audio;
          this.drawAudio();
          this.audio_loading = false;
        }
        this.loadSegments();
        this.loadAnnotations();
      }).finally(() => {
        this.loading = false;
      })
    },
    drawAudio() {
      let wavesurfer = WaveSurfer.create({
        container: '#waveform',
        height: 100,
        controls: true,
        scrollParent: true,
        barHeight: 2,
        barGap: 0,
        barRadius: 1,
        normalize: true,
        // minimap: true,
        backend: 'MediaElement',
        cursorColor: '#C4112E',
        progressColor: '#3f4045',
        waveColor: '#7D8389',
        plugins: [
          WaveSurferRegions.create(),
          WaveSurferMarkers.create()
          // WaveSurferMinimap.create({
          //   height: 30,
          //   waveColor: '#ddd',
          //   progressColor: '#999',
          //   cursorColor: '#999'
          // }),
          // WaveSurferTimeline.create({
          //   container: '#wave-timeline'
          // })
        ]
      });
      this.wavesurfer = wavesurfer;
      wavesurfer.on('play', () => {
        this.playing = true;
      });
      wavesurfer.on('pause', () => {
        this.playing = false;
      });
      wavesurfer.on('audioprocess', () => {
        this.playTime = this.wavesurfer.getCurrentTime();
      });
      wavesurfer.zoom(-10)
      wavesurfer.load(
        this.audio_url,
        //this.audio_data
      );

      /* Regions */

      wavesurfer.on('ready', () => {
        this.audio_loading = false;
        this.max_length = wavesurfer.getDuration()

        // wavesurfer.enableDragSelection({
        //   color: randomColor(0.1)
        // });

        // fetch('https://wavesurfer-js.org/example/annotation/annotations.json')
        //     .then(r => r.json())
        //     .then(data => {
        //       this.loadRegions(data);
        //     });
      });
    },
    async checkAudio() {
      return new Promise((resolve, reject) => {
        this.$http.get('/sessions/' + this.id + '/audio')
          .then(({data}) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          })
      })
    },
    loadSegments() {
      this.segments = [];
      this.$http.get(`/sessions/${this.id}/segments`).then(({data}) => {
        this.segments = data.data;
        this.segments_loaded = true;
        this.loadRegions(this.segments)
      }).catch(({response}) => {
        console.log(response)
      })
    },
    loadAnnotations() {
      this.annotations = [];
      this.$http.get(`/sessions/${this.id}/annotations`).then(({data}) => {
        this.annotations = data.data;
        this.annotations_loaded = true;
        this.loadMarkers(this.annotations)
      }).catch(({response}) => {
        console.log(response)
      })
    },
    toggleOpenSegment(id) {
      if (!this.openEditSegments.includes(id)) {
        this.openEditSegments.push(id);
      } else {
        this.openEditSegments = this.openEditSegments.filter(i => i !== id);
      }
    },
    toggleOpenAnnotation(id) {
      if (!this.openEditAnnotations.includes(id)) {
        this.openEditAnnotations.push(id);
      } else {
        this.openEditAnnotations = this.openEditAnnotations.filter(i => i !== id);
      }
    },
    deleteClass(item) {
      this.$refs.delete_modal.open(item);
    },
    destroyClass(item) {
      this.$http.delete('/sessions/' + item).then(() => {
        this.$router.push({name: 'Classes'}).then(() => {
          this.$noty.success('Deleted!');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
      });
    },

    loadMarkers(markers) {
      if(!this.wavesurfer){
        return;
      }
      markers.forEach((marker) => {
        this.loadMarker(marker)
      });
    },
    loadRegions(regions) {
      if(!this.wavesurfer){
        return;
      }
      regions.forEach((region) => {
        this.loadRegion(region)
      });
    },
    loadRegion(region) {
      let item = {
        data: {
          id: region.id,
        },
        drag: false,
        resize: false,
        color: region.color,
        start: region.start,
        end: region.end,
      }
      this.wavesurfer.addRegion(item);
    },
    loadMarker(marker) {
      var newDiv = document.createElement("span");
      newDiv.width = 30;
      newDiv.height = 30;
      newDiv.classList.add('markered');
      let item = {
        data: {
          id: marker.id,
        },
        time: marker.instant,
        label: marker.name,
        position: 'top',
        markerElement: newDiv
      }
      this.wavesurfer.addMarker(item);
    },
  },
  computed: {
    all_loaded() {
      return this.segments_loaded && this.annotations_loaded;
    },
    all_items() {
      let items = this.segments;
      return items.concat(this.annotations).sort((a, b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
    },
    maxTime() {

      if (!this.audio_loading) {
        let s = this.max_length;
        let m = Math.floor(s / 60);
        s = Math.round(s % 60);
        if (m < 10) {
          m = '0' + m;
        }
        if (s < 10) {
          s = '0' + s;
        }
        return m + ':' + s;
      }

      return !this.audio_loading ? new Date(this.max_length * 1000).toISOString().substr(14, 5) : '00:00'
    },
    currentTime() {
      return !this.audio_loading ? new Date(this.playTime * 1000).toISOString().substr(14, 5) : '00:00'
    },
  },
}
</script>

<style scoped>
div >>> .form-group {
  margin-bottom: 2rem !important;
}
</style>
