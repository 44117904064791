<template>
  <div>
    <h1 class="fw-bold text-uppercase my-4">Manage Classes</h1>
    <div class="row align-items-center py-2">
      <div class="col-xl-6  d-flex align-items-center">
        <div class="dropdown me-3">
          <button class="btn btn-outline-primary dropdown-toggle rounded-pill" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
            {{ statuses.find(item => item.value === filters.status).label }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li v-for="status in statuses" :key="status.value">
              <a class="dropdown-item" href="#"
                 @click.prevent="changeStatus(status)">{{ status.label }}</a>
            </li>
          </ul>
        </div>
        <ul class="list-unstyled d-flex align-items-center mb-0 flex-wrap">
          <li v-for="status in help_statuses" :key="status.value" class="me-3">
            <span class="status-badge me-2" :class="{
                  'bg-primary': status.value === 'released',
                  'bg-secondary': status.value === 'draft',
                  'bg-success': status.value === 'ready',
                  'bg-warning': status.value === 'filmed',
                }"></span>
              {{ status.label }}
            </li>
        </ul>
      </div>
      <div class="col-xl-6 d-flex align-items-center flex-column flex-sm-row">
        <button class="btn btn-primary ms-sm-auto my-sm-0 my-2 text-uppercase rounded-pill py-2 col-12 col-sm-auto"
                @click="$router.push({name:'CreateClass'})">Create New Class
        </button>
        <text-input v-model="filters.search" placeholder="Search" class="ms-2 col-12 col-sm-auto" inline @change="fetchData" >
          <template #append>
            <span class="eye-btn"><i class="far fa-search"></i></span>
          </template>
        </text-input>
      </div>
    </div>
    <div class="card rounded mt-2 border-0 shadow">
      <main-table :data="data" :columns="fields" :options="options"
                  @update:sorter-value='sortingChange' @paginate="pageChange" @per_page_change="perPageChange"
                  :loading="loading">
        <template #name="{item}">
            <span data-bs-toggle="tooltip" data-bs-placement="top" :title="item.status"
                  class="d-flex justify-content-between align-items-center">
              <span>
              {{ item.name }}
              </span>
              <span class="status-badge me-1" :class="{
                'bg-primary': item.status === 'released',
                'bg-secondary': item.status === 'draft',
                'bg-success': item.status === 'ready',
                'bg-warning': item.status === 'filmed',
              }"></span>
            </span>
        </template>
        <template #production_date="{item}">
          {{ $formatDate(item.production_date) }}
        </template>
        <template #production_time="{item}">
          {{ $formatTime(item.production_date) }}
        </template>
        <template #release_date="{item}">
          {{ $formatDate(item.release_date) }}
        </template>
        <template #actions="{item}">
          <div class="d-flex align-items-center">
            <router-link :to="{name:'ClassDetails', params:{id:item.id}}"
                         class="text-uppercase me-2 text-decoration-none" title="view">View
            </router-link>
            <router-link :to="{name:'EditClass', params:{id:item.id}}"
                         class="rounded-circle action_btn me-2 text-white bg-dark" title="edit"><i
                class="fal fa-edit"></i></router-link>
            <a href="#" @click.prevent="copyClass(item)"
               class="rounded-circle action_btn me-2 text-white bg-dark" title="Clone"><i
                class="fal fa-copy"></i></a>
          </div>
        </template>
      </main-table>
    </div>
    <action-confirm-modal ref="clone_modal" @confirm="cloneClass"></action-confirm-modal>
  </div>
</template>
<script>
import externalTable from "@/mixins/externalTable";
import {Tooltip, Popover} from 'bootstrap'
import MainTable from "@/components/MainTable";
import TextInput from "@/components/form/TextInput";
import ActionConfirmModal from "@/components/ActionConfirmModal";

export default {
  name: "Classes",
  components: {ActionConfirmModal, TextInput, MainTable},
  mixins: [externalTable],
  data() {
    return {
      options: {
        order_by: 'id',
        order: 'desc'
      },
      fields: [
        // {
        //   key: 'id',
        //   label: 'ID',
        //   slot: false,
        //   _header_classes: 'text-uppercase',
        // },
        {
          key: 'name',
          label: 'Title',
          slot: true,
          _header_classes: 'text-uppercase',
        },
        {
          key: 'class_type',
          label: 'Type',
          _header_classes: 'text-uppercase',
        },
        {
          key: 'length',
          label: 'Length',
          _header_classes: 'text-uppercase',
        },
        {
          key: 'language',
          label: 'Language',
          _header_classes: 'text-uppercase',
        },
        {
          key: 'production_date',
          slot: true,
          sort: 'production_date',
          label: 'Production Date',
          _header_classes: 'text-uppercase',
        },
        {
          key: 'production_time',
          slot: true,
          sort: 'production_date',
          label: 'Time',
          _header_classes: 'text-uppercase',
        },
        {
          key: 'release_date',
          slot: true,
          label: 'Release Date',
          _header_classes: 'text-uppercase',
        },
        {
          key: 'actions',
          slot: true,
          sort: false,
          label: 'Actions',
          _header_classes: 'text-uppercase',
        },
      ],
      filters: {
        status: null,
        search: '',
      },
      sessions: [],
      statuses: [
        {
          value: null,
          label: 'All'
        },
        {
          value: 'draft',
          label: 'Draft'
        },
        {
          value: 'ready',
          label: 'Ready'
        },
        {
          value: 'filmed',
          label: 'Filmed'
        },
        {
          value: 'released',
          label: 'Released'
        },
      ],
      url: '/sessions'
    }
  },
  mounted() {
    this.fetchData();
    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl)
    })
  },
  computed: {
    help_statuses() {
      return this.statuses.filter(item => item.value)
    }
  },
  methods: {
    copyClass(item) {
      this.$refs.clone_modal.open(item);
    },
    changeStatus(status) {
      this.filters.status = status.value;
      this.fetchData();
    },
    endLoading() {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
      })
    },
    cloneClass(item) {
      this.$http.put('/sessions/' + item.id + '/clone').then(() => {
        this.$noty.success('Copied!');
        this.fetchData()
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
      });
    }
  }
}
</script>
<style scoped>

.action_btn {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
</style>
