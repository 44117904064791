<template>
  <div class="auth-bg">
    <div class="logo">
      <img alt="Spinning" src="../assets/spinning_white.svg">
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "AuthBg"
}
</script>

<style scoped lang="scss">
.auth-bg {
  min-height: 100vh;
  padding: 80px 0 100px;
  display: flex;
  align-items: center;
  background-size: cover;
  > .container{
    position: relative;
    z-index: 2;
  }
  > .logo {
    position: absolute;
    left: 32px;
    top: 32px;
    z-index: 0;
    height: 60px;
  }
  > .back_logo {
    position: absolute;
    right: 32px;
    bottom: 32px;
    /* width: 150px; */
    z-index: 0;
    img {
      width: 220px;
    }
  }
  @media screen and (max-width: 768px) {
    > .logo {
      position: absolute;
      left: 30px;
      top: 30px;
      z-index: 0;
      img {
        width: 220px;
      }
    }
    > .back_logo {
      right: 30px;
      bottom: 30px;
      /* width: 150px; */
      z-index: 0;
      img {
        width: 150px;
      }
    }
  }
}

</style>
